<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h3 class="mb-0 text-primary">Kiosks</h3>
      <button
        class="btn btn-sm btn-success text-uppercase font-weight-bold"
        v-b-modal.new-kiosk-modal
      >
        + Add New Kiosk(s)
      </button>
    </div>
    <div class="separator-breadcrumb border-top"></div>
    <div class="card">
      <div class="card-body">
        <ul
          class="nav nav-tabs nav-justified mb-4 text-uppercase font-weight-bold"
        >
          <li class="nav-item">
            <a
              class="nav-link active"
              id="home-icon-pill"
              data-toggle="pill"
              @click="setActiveTab('Manufacturer')"
              role="tab"
              aria-controls="homePIll"
              aria-selected="true"
            >
              <i class="nav-icon i-Settings-Window mr-1"></i>
              Register Kiosk
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="profile-icon-pill"
              data-toggle="pill"
              @click="setActiveTab('In+Transit')"
              role="tab"
              aria-controls="profilePIll"
              aria-selected="false"
              ><i class="nav-icon i-Bird-Delivering-Letter mr-1"></i> Transit</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="contact-icon-pill"
              data-toggle="pill"
              href="#contactPIll"
              @click="setActiveTab('Delivered')"
              role="tab"
              aria-controls="contactPIll"
              aria-selected="false"
            >
              <i class="nav-icon i-Home1 mr-1"></i> Delivered</a
            >
          </li>
        </ul>
        <DataTable
          :columns="columns"
          :rows="kiosks"
          :activeTab="activeTab"
          @display-edit-form="displayEditForm"
          @display-qr-code="displayQrCode"
          @get-kiosks="getKiosks"
        />
      </div>
    </div>

    <b-modal ref="edit-modal" hide-footer hide-backdrop>
      <KioskForm :kiosk="activeKiosk" />
    </b-modal>
    <b-modal ref="kiosk-qr" hide-footer>
      <KioskQrCode :kiosk="activeKiosk" />
    </b-modal>
    <b-modal id="new-kiosk-modal" ref="new-kiosk" hide-footer hide-backdrop>
      <KioskForm
        @get-kiosks="getKiosks"
        @hide-new-kiosk-modal="hideNewKioskModal"
      />
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import KioskForm from "../../components/Kiosk/KioskForm";
import DataTable from "../../components/DataTable";
import KioskQrCode from "../../components/Kiosk/KioskQrCode";

export default {
  components: {
    DataTable,
    KioskForm,
    KioskQrCode
  },
  data() {
    return {
      error: "",
      kiosks: [],
      activeKiosk: {},
      activeTab: "Manufacturer"
    };
  },
  computed: {
    columns() {
      const columns = [
        { field: "serial_number", label: "serial_number" },
        { field: "created", label: "Created", formatFn: this.formatDateTime },
        { field: "qr_code", label: "Qr Code", sortable: false }
      ];
      if (this.activeTab === "Manufacturer") {
        const actionRow = { field: "action", label: "Action", sortable: false };
        columns.splice(2, 0, actionRow);
      }
      return columns;
    }
  },
  mounted() {
    this.getKiosks(this.activeTab);
  },
  methods: {
    setActiveTab(tab) {
      this.activeTab = tab.split(" ").join("+");
      this.getKiosks(this.activeTab);
    },
    displayQrCode(kiosk) {
      this.activeKiosk = kiosk;
      this.$refs["kiosk-qr"].show();
    },
    displayEditForm(kiosk) {
      this.activeKiosk = kiosk;
      this.$refs["edit-modal"].show();
    },
    hideNewKioskModal() {
      this.$refs["new-kiosk"].hide();
    },
    formatDateTime(value) {
      return this.$moment(value).format("dddd, MMMM Do YYYY, h:mm a");
    },
    async getKiosks(location, clearKiosks) {
      if (clearKiosks) {
        this.kiosks = [];
      }
      // const access_token = localStorage.getItem("access_token");
      let kiosks_url = `v1/kiosks/`;
      if (location) {
        kiosks_url = `v1/kiosks/?location=${location}`;
      }
      await axios
        .get(kiosks_url)
        .then(response => {
          if (response.status === 200) {
            this.kiosks = response.data.results;
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.$swal(
              "Error",
              `logged out due to an expired session`,
              "error"
            );
          } else {
            console.log("here");
            this.$swal("Error", `${error.response.data.detail}`, "error");
          }
        });
    }
  }
};
</script>
