// serial number,purchase date and status
<template>
  <div>
    <div class="row">
      <div class="col-md-12 mx-auto">
        <h4 class="text-left mb-3">{{ submit }}</h4>
        <div class="d-flex flex-column">
          <div v-if="kiosk.id" class="form-group">
            <input
              v-model="kiosk.serial_number"
              type="text"
              class="form-control"
              placeholder="Serial Number"
            />
          </div>
          <div v-else class="form-group">
            <label for="number">
              Number of kiosks:
            </label>
            <input
              v-model="kiosk.kiosks"
              id="number"
              type="number"
              class="form-control"
              placeholder="Number of kiosks"
              min="1"
            />
          </div>
          <div class="text-center my-3">
            <button class="btn btn-success w-50" @click="addKiosk">
              {{ submit }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "KioskForm",
  data() {
    return {
      submit: "Add New Kiosk(s)"
    };
  },
  props: {
    kiosk: {
      type: Object,
      default() {
        return { kiosks: 1 };
      }
    }
  },
  mounted() {
    this.toggleTitle();
  },
  methods: {
    toggleTitle() {
      if (this.kiosk.id) {
        this.submit = "Edit Kiosk";
      }
    },
    async addKiosk() {
      if (this.kiosk.id) {
        this.updateKiosk();
      } else {
        this.submitting = true;
        this.submit = "Adding Kiosks...";
        const kiosk = {
          kiosks: this.kiosk.kiosks,
          location: "Manufacturer"
        };
        await axios
          .post(`v1/kiosks/`, kiosk)
          .then(response => {
            if (response.status === 201) {
              this.$emit("hide-new-kiosk-modal");
              this.$emit("get-kiosks", "Manufacturer", true);
              if (response.data.detail) {
                this.$swal("Sucsess", `${response.data.detail}`, "success");
              } else {
                this.$swal("Sucsess", "kiosk Added Successfully", "success");
              }
            }
          })
          .catch(error => {
            console.log(error);
            if (error.response) {
              if (error.response.data.detail) {
                this.$swal("Error", `${error.response.data.detail}`, "error");
              }
              this.responseErrors = error.response.data;
              this.loading = false;
              this.submit = "Add New Kiosk(s)";
            }
          });
      }
    },
    async updateKiosk() {
      this.submitting = true;
      this.submit = "Updating Kiosk...";
      await axios
        .patch(`v1/kiosks/${this.kiosk.id}/`, this.kiosk)
        .then(response => {
          if (response.status === 200) {
            this.$emit("get-kiosks", this.kiosk.location, true);
            this.$emit("hide-new-kiosk-modal");
            this.$swal("Success", "kiosk Updated Successfully", "success");
          }
        })
        .catch(error => {
          if (error.response) {
            this.responseErrors = error.response.data;
            this.loading = false;
          }
        });
    }
  }
};
</script>
