<template>
  <div>
    <div class="row mb-3">
      <div id="qr" class="col-md-12 text-center">
        <QrcodeVue :value="productQrData" :size="'300'" :renderAs="'svg'" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-center">
        <b-button class="outline-success" @click="print"
          >Print Qr code</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import QrcodeVue from "qrcode.vue";
export default {
  name: "KioskQrCode",
  components: {
    QrcodeVue
  },
  props: {
    kiosk: {
      type: Object,
      default() {
        return {
          serial_number: ""
        };
      }
    }
  },
  computed: {
    productQrData() {
      const data = {
        id: this.kiosk.id,
        serial_number: this.kiosk.serial_number,
        type: "kiosk"
      };
      return JSON.stringify(data);
    }
  },
  methods: {
    print() {
      this.$htmlToPaper("qr");
    }
  }
};
</script>
